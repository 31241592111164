import './custom-node.css';
import CustomNode from './CustomNode';


function ReturnSidebar({ data }) {
  return (
    <>
      <div style={{padding: "10px"}}>
        {data.result ? <a href={data.result} target="_blank" rel="noreferrer">Result</a>: <></>}
        <h4>Return the result of the pipeline</h4>
        <p>Click on the "Launch Pipeline" button !</p>
      </div>
    </>
  );

}

function ReturnNode({ data }) {

  return (
    <CustomNode
      title={"Return parameters"}
      data={data}
      dataLeft={["image"]}
    />
  );

}

export { ReturnSidebar, ReturnNode};