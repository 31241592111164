import './App.css';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { Main } from './components/main/Main';

function App() {
  return (
    <>
      <Header/>
      <div className='App'>
        <div className='Main'>
          <Main />
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default App;