import './custom-node.css';

import CustomNode from './CustomNode';

function GroupNode({ data }) {

  return (
    <CustomNode 
        title={"Group"} 
        data={data}
        isGroupNode={true}
    />
  )
}

export { GroupNode};