import recordsJson from '../../../assets/records.json';
import { Neo4JDriver } from '../../../services/neo4j';

class GraphStats {
    public static nodes = this.filterNodes();
    public static edges = this.filterEdges();


    public static filterNodes() {
        var nodes = [];
        for (const nodeLabel in recordsJson[0].value) {
            var node = recordsJson[0].value[nodeLabel];
            node.label = nodeLabel;
            if (node.type === 'node') nodes.push(node);
        }
        return nodes;
    }

    public static filterEdges() {
        var edges = [];
        for (const edgeLabel in recordsJson[0].value) {
            var edge = recordsJson[0].value[edgeLabel];
            edge.label = edgeLabel;
            if (edge.type === 'relationship') edges.push(edge);
        }
        return edges;
    }
}

class Node {

    public static totalID: number = 1;

    public children: Node[] = [];
    public parent: Node | Tree | null = null;

    public labelSubject: string = '';
    public labelRelation: string = '';
    public labelObject: string = '';

    public optional: boolean = false;

    public id: number;
    public size: number = 12;

    public constructor() {
        this.id = Node.totalID++;
    }

    public emptySubTree(): void {
        this.children = [];
    }

    public removeNode(rmID: number): void {
        this.children = this.children.filter((child) => {
            child.removeNode(rmID);
            return child.id !== rmID;
        });
    }

    public addSibling(): void {
        var sibNode: Node = new Node();
        sibNode.size = this.size;
        sibNode.labelSubject = this.labelSubject;

        this.parent.children.push(sibNode);
        sibNode.parent = this.parent;
    }

    public addChild(): void {
        var chiNode: Node = new Node();
        chiNode.size = this.size - 1;
        chiNode.labelSubject = this.labelObject;

        this.children.push(chiNode);
        chiNode.parent = this;
    }

    public setOptional(): void {
        if (!this.parent.optional) {
            this.children.forEach((child) => child.setOptional());
            this.optional = !this.optional;
        }
    }




    public getSubLabels() {
        return Neo4JDriver.labels.map(label => { return { name: label } });
    }

    public getRelLabels() {
        if (this.labelSubject === '') return [];
        return Neo4JDriver.startToRel[this.labelSubject].map(label => { return { name: label } });
    }

    public getObjLabels() {
        if (this.labelRelation === '') return [];
        return Neo4JDriver.relToEnd[this.labelRelation].map(label => { return { name: label } });
    }


    public getDataProperty() {
        if (this.labelObject === '') return [];

        var dataProperties = [];

        for (const property in recordsJson[0].value[this.labelObject].properties) {
            dataProperties.push({ name: property, type: recordsJson[0].value[this.labelObject].properties[property].type });
        }

        return dataProperties;
    }



    public toCYPHER(parentNodeLabel: string): string {
        var newNodeLabel = `${this.labelObject}_${this.id}`;
        var subQuery = `MATCH (${parentNodeLabel}:${this.labelSubject})-[:${this.labelRelation}]->(${newNodeLabel}:${this.labelObject}) WHERE true \n`;
        if (this.optional) subQuery = `OPTIONAL ` + subQuery;
        this.children.forEach((child) => subQuery += child.toCYPHER(newNodeLabel));

        return subQuery;
    }

    public toSPARQL(parentNodeLabel: string): string {
        var newNodeLabel = `${this.labelObject}_${this.id}`;
        var subQuery = `?${parentNodeLabel} rdf:type ${this.labelSubject}; ${this.labelRelation} ${newNodeLabel} . ${newNodeLabel} rdf:type ${this.labelObject} . \n`;
        if (this.optional) subQuery = `OPTIONAL ` + subQuery;
        this.children.forEach((child) => subQuery += child.toSPARQL(newNodeLabel));

        return subQuery;
    }

}

class Tree {
    public children: Node[] = [];
    public root: boolean = true;
    public optional: boolean = false;

    public constructor() {
        var firstNode: Node = new Node();

        this.children.push(firstNode);
        firstNode.parent = this;
    }

    public removeNode(rmID: number): void {
        this.children = this.children.filter((child) => {
            child.removeNode(rmID);
            return child.id !== rmID;
        });
    }

    public toCYPHER(): string {
        var label = this.children[0].labelSubject;
        var firstNodeLabel = `${label}_0`;
        var query = "";
        this.children.forEach((child) => query += child.toCYPHER(firstNodeLabel));

        query += "RETURN * LIMIT 25"

        return query;
    }

    public toSPARQL(): string {
        var label = this.children[0].labelSubject;
        var firstNodeLabel = `${label}_0`;

        var query = "PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\nSELECT DISTINCT ?Artwork_1 ?Artwork_1_label WHERE {\n";

        this.children.forEach((child) => query += child.toSPARQL(firstNodeLabel));

        query += "}\nLIMIT 25"

        return query;
    }
}

export { Tree, Node }