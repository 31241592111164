import './custom-node.css';

//import { CypherEditor } from '@neo4j-cypher/react-codemirror';
import CustomNode from './CustomNode';
import { Autocomplete, TextField } from '@mui/material';


function DataQuerySidebar({nodeSelected, setNodes, labels}) {

  const handleValueChange = (event, value, reason) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) { node.data.options.query = value; }
        return node;
      })
    )
  };

  /*<CypherEditor
    style={{textAlign: "start"}}
    onValueChanged={handleValueChange}
    value={nodeSelected.data.options.query}
  />*/
  return (
    <div style={{padding: "10px"}}>
      <h4>Select subgraph with labels : </h4>
      <Autocomplete
        multiple
        value={nodeSelected.data.options.query}
        options={labels}
        onChange={handleValueChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Filter Labels"
            placeholder="Labels"
          />
        )}
      />
      <p>Output rows</p>
    </div>
  );

}

function DataQueryNode({ data }) {

  return (
    <CustomNode
      data={data}
      title={"Query"}
      dataRight={["rows"]}
    />
  )
}

export { DataQuerySidebar, DataQueryNode};