import { AppBar, Toolbar, Typography } from "@mui/material";

function Header() {

    return (
        <div className="header">
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>AIDD4H</Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export {Header};