import React from 'react';
import ciadlogo from '../../assets/ciad.png';
import opmlogo from '../../assets/opm.png';
import aidd4hlogo from '../../assets/aidd4h.png';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
        <img src={ciadlogo} alt="ciad" className="footer-logo" />
        <img src={opmlogo} alt="opm" className="footer-logo" />
        <img src={aidd4hlogo} alt="aidd4h" className="footer-logo" />
    </div>
  );
}

export { Footer };