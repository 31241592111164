
import { Card, CardContent, CardHeader, CardMedia, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';


//import NeoVis, {NEOVIS_ADVANCED_CONFIG, NEOVIS_DEFAULT_CONFIG, NeoVisEvents} from "neovis.js/dist/neovis";
/*const PersonPNG = require('../../../../assets/image/person.png');
const ProductorPNG = require('../../../../assets/image/productor.png');
const MoviePNG = require('../../../../assets/image/movie.png');*/

function NodeInfoComponent({nodeInfo}: any) {

    const [description, setDescription] = useState([]);

    useEffect(() => {

        let desc = [];

        for (const prop in nodeInfo.raw.properties) {
            desc.push(
            <ListItem key={prop}>
                <ListItemText primary={prop} secondary={nodeInfo.raw.properties[prop]} />
            </ListItem>)
        }

        setDescription(desc);

    }, [nodeInfo])

    return (
        <Card>
            <CardHeader
                title={nodeInfo.label}
                subheader={nodeInfo.raw.labels[0]}
            />
            <CardMedia
                component="img"
                height="128"
                sx={{objectFit: "contain"}}
                image={nodeInfo.image}
            />
            <CardContent>
                <List>
                    {description}
                </List>
            </CardContent>
        </Card>
    )

}


export {NodeInfoComponent};
