import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { DataQuerySidebar } from './custom-nodes/DataQueryNode';
import { TransformToGraphSidebar } from './custom-nodes/TransformToGraphNode';
import { EmbeddingMetapathSidebar } from './custom-nodes/EmbeddingMetapathNode';
import { ReturnSidebar } from './custom-nodes/ReturnNode';
import { EmbeddingNode2VecSidebar } from './custom-nodes/EmbeddingNode2VecNode';
import { SelectMetapathSidebar } from './custom-nodes/TransformAddMetapathNode';

import { nodeData } from './data-node';

function NodeChoiceChomponent({ category }) {

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{category.category}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {category.options.map((option, index) => 
                    <div 
                        style={{border: '1px solid #1a192b', padding: '4px', marginBottom: '10px'}}
                        onDragStart={(event) => onDragStart(event, option)}
                        key={index}
                        draggable
                    >{nodeData[option].name}</div>
                )}
            </AccordionDetails>
        </Accordion>
    );
}


function SidebarComponent({ nodeSelected, nodeCategories, nodes, setNodes, labels }) {
    
    if (nodeSelected === null) 
        return <>
            {nodeCategories.map((category, index) => (
                <NodeChoiceChomponent key={index} category={category} />
            ))}
        </>;
    else if (nodeSelected.type === 'data-query-node')
        return (
            <DataQuerySidebar nodeSelected={nodeSelected} setNodes={setNodes} labels={labels} />
        );
    else if (nodeSelected.type === 'transform-data2graph-node')
        return (
            <TransformToGraphSidebar />
        );
    else if (nodeSelected.type === 'transform-add-metapath-node')
        return (
            <SelectMetapathSidebar nodeSelected={nodeSelected} setNodes={setNodes} labels={labels} />
        );
    else if (nodeSelected.type === 'embedding-metapath-node')
        return (
            <EmbeddingMetapathSidebar nodeSelected={nodeSelected} setNodes={setNodes} />
        );
    else if (nodeSelected.type === 'embedding-node2vec-node')
        return (
            <EmbeddingNode2VecSidebar nodeSelected={nodeSelected} setNodes={setNodes} />
        );
    else if (nodeSelected.type === 'final-return-node') 
        return (
            <ReturnSidebar data={nodeSelected.data} />
        )

}

export default SidebarComponent;
