
import NeoVis, {NEOVIS_ADVANCED_CONFIG, NEOVIS_DEFAULT_CONFIG, NeoVisEvents} from "neovis.js/dist/neovis";
import { NeoCredentials } from "./neo4j";
import { QueryBuilder } from "./query";


class NeovisBuilder {
    private static neovis: NeoVis;

    private static config: any = {
        containerId: "viz",
        neo4j: {
            serverUrl: NeoCredentials.getUrl(), //"bolt://localhost:7687",
            serverDatabase: NeoCredentials.getDatabase(),
            serverUser: NeoCredentials.getUsername(), //"neo4j",
            serverPassword: NeoCredentials.getPassword(), //"password",
        },
        visConfig: {
            physics: {
                solver: 'repulsion',
                repulsion: {
                    nodeDistance: 1000,
                    damping: 0.65,
                },
            },
            nodes: {
                physics: true,
                shape: 'dot',
            },
            edges: {
                arrows: {to: {enabled: true}},
                physics: true,
                smooth: {enabled: true}
            },
            //layout: {hierarchical: {enabled: true}}
        },
        labels: {
            [NEOVIS_DEFAULT_CONFIG]: {
                label: "name",
                /*[NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                    function: {
                        title: (node) => {let a = objectToTitleHtml(node, ["name"]); console.log(a); return a; },
                        label: (node) => {let a = objectToTitleHtml(node, ["name"]); console.log(a); return a; },
                    }
                }*/
            },
        },
        relationships: {
            [NEOVIS_DEFAULT_CONFIG]: {
                arrows: 'to',
                [NEOVIS_ADVANCED_CONFIG]: {
                    function: {
                        label: (rel: any) => rel.type//{console.log(rel); return rel.type}
                    },
                }
            }
        },
        initialCypher: QueryBuilder.buildQuery(),
    };


    public static create(setNodeInfo: any) {
        
        this.neovis = new NeoVis(this.config)
        
        this.neovis.render();
        this.neovis.registerOnEvent(NeoVisEvents.CompletionEvent, (e) => {
            // Une fois 'completed', on peut se servir de network (vis.js)
            this.neovis.network.on('doubleClick', (event) => {
                if (event.nodes.length > 0) {
                    let node: any = this.neovis.nodes.get(event.nodes[0]);
                    this.neovis.updateWithCypher(QueryBuilder.buildUpdateQuery(node.id));
                }
            });

            this.neovis.network.on("dragEnd", (event) => {
                if (event.nodes.length > 0) {
                    let node: any = this.neovis.nodes.get(event.nodes[0]);
                    node.physics = false;
                    this.neovis.nodes.update([node]);
                }
            });

            this.neovis.network.on("click", (event) => {
                if (event.nodes.length > 0) {
                    let node = this.neovis.nodes.get(event.nodes[0]);
                    setNodeInfo(node);
                }
                else {
                    setNodeInfo(null);
                }
            });
            
        });
    }

    public static render() {
        //this.neovis.clearNetwork();
        this.neovis.renderWithCypher(QueryBuilder.buildQuery());
    }

    public static getNeovis() {
        return this.neovis;
    }

}

export {NeovisBuilder}
