
import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';

import 'reactflow/dist/style.css';
import {ReturnNode} from './custom-nodes/ReturnNode';
import {DataQueryNode} from './custom-nodes/DataQueryNode';
import {TransformToGraphNode} from './custom-nodes/TransformToGraphNode';
import {TransformAddMetapathNode} from './custom-nodes/TransformAddMetapathNode';
import {EmbeddingNode2VecNode} from './custom-nodes/EmbeddingNode2VecNode';
import {EmbeddingMetapathNode} from './custom-nodes/EmbeddingMetapathNode';
import {ReductionTSNENode} from './custom-nodes/ReductionTSNENode';
import {VisualisationPlotNode} from './custom-nodes/VisualisationPlotNode';

import { grey } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import SidebarComponent from './SidebarComponent';

import { nodeCategories, initialEdges, initialNodes } from './data-node';
import { GroupNode } from './custom-nodes/GroupNode';
import { createNode2VecExample, createMetapathExample, createNewNode, createPipeline, customAddEdge } from './utils';
import { Neo4JDriver } from '../../../services/neo4j';


const nodeTypes = { 
    "data-query-node": DataQueryNode, 
    "transform-data2graph-node": TransformToGraphNode ,
    "transform-add-metapath-node": TransformAddMetapathNode ,
    "embedding-node2vec-node": EmbeddingNode2VecNode ,
    "embedding-metapath-node": EmbeddingMetapathNode ,
    "reduction-tsne-node": ReductionTSNENode ,
    "visualisation-plot-node": VisualisationPlotNode ,
    "final-return-node": ReturnNode,
    "group-node": GroupNode
};


function PipelineComponent() {
    const reactFlowWrapper = useRef(null);      
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodeSelected, setNodeSelected] = useState(null)
    const [token] = useState(uuidv4());

    const [labels, setLabels] = useState<any[]>([]);

    const custSetLabels = (labels) => {
        setLabels(labels.map((val) => val.label));
    };

    useEffect(() => {
        Neo4JDriver.getLabels(custSetLabels);
    }, []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const nodeType = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof nodeType === 'undefined' || !nodeType) {
        return;
      }


      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      if (nodeType === "node2vec-example") createNode2VecExample(position, setNodes, setEdges);
      else if (nodeType === "metapath-example") createMetapathExample(position, setNodes, setEdges)
      else createNewNode(nodeType, position, setNodes);

    }, [reactFlowInstance, setNodes, setEdges]
  );

    const editNode = (nodeId, newData) => {
        setNodes((nds) => 
            nds.map((nd) => {
                if (nd.id === nodeId) { nd.data = {...nd.data, ...newData} }
                return nd;
            })
        )
    }

    const nodeClickHandler = (evt, node) => {
        if (nodeSelected != null && nodeSelected.type !== 'group-node') {
            editNode(nodeSelected.id, {selected: false});
        }
        if (node.type !== 'group-node') {
            editNode(node.id, {selected: true});
            setNodeSelected(node);
        }
    }

    const paneClickHandler = (evt) => {
        if (nodeSelected != null && nodeSelected.type !== 'group-node') {
            editNode(nodeSelected.id, {selected: false});
        }
        setNodeSelected(null);
    }

    const onConnect = useCallback((params) => setEdges((eds) => customAddEdge(params, eds)), [setEdges]);

    return (
        <div style={{height: 750, display: 'flex'}}>
            <ReactFlowProvider>
                <div className="reactflow-wrapper" style={{height: '100%', width: '80%', position: 'relative'}} ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onNodeClick={nodeClickHandler}
                        onPaneClick={paneClickHandler}
                        onConnect={onConnect}
                        onInit={setReactFlowInstance}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                    >
                        <MiniMap />
                        <Controls />
                        <Background />
                    </ReactFlow>
                </div>
                <Box sx={{backgroundColor: grey[100], border: '3px rounded black', padding:"2%", width: '20%', position: 'relative'}}>
                    <SidebarComponent
                        nodeSelected={nodeSelected}
                        nodeCategories={nodeCategories}
                        nodes={nodes}
                        setNodes={setNodes}
                        labels={labels}
                    />
                    <div>
                        <Button onClick={()=>createPipeline(reactFlowInstance, nodes, edges, token, editNode)}>
                            Launch Pipeline
                        </Button>
                    </div>
                </Box>
            </ReactFlowProvider>
        </div>
    );
}

export default PipelineComponent;