import {  useState } from 'react';
import './custom-node.css';

import { Slider, Typography } from '@mui/material';
import CustomNode from './CustomNode';

function CustomSlider({onChange, name, value, setValue, min, max, step=null, scale=null}) {
  
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
    onChange();
  };

  if (step === null) step = 1;
  if (scale === null) scale = (val) => val;

  return (
    <>
      <Typography gutterBottom>{name} : {scale(value)}</Typography>
      <Slider 
        value={value}
        min={min}
        max={max}
        step={step}
        scale={scale}
        onChange={handleChange}
      />
    </>
  )
}

function EmbeddingMetapathSidebar({nodeSelected, setNodes}) {

  const onChange = () => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) { 
          node.data.options.walk_length = walkLength; 
          node.data.options.random_walk_per_node = randomWalkPerNode; 
          node.data.options.vector_size = 2**vectorSize; 
          node.data.options.window_val = windowVal; 
          node.data.options.min_count = minCount; 
          node.data.options.workers = workers; 
          node.data.options.epochs = epochs; 
        }
        return node;
      })
    )
  };

  const [walkLength, setWalkLength] = useState(10);
  const [randomWalkPerNode, setRandomWalkPerNode] = useState(1);
  const [vectorSize, setVectorSize] = useState(6);
  const [windowVal, setWindowVal] = useState(5);
  const [minCount, setMinCount] = useState(0);
  const [workers, setWorkers] = useState(2);
  const [epochs, setEpochs] = useState(1);
  

  return (
    <div style={{padding: "10px"}}>
      <h4>Parameters for Metapath Embedding : </h4>
      <CustomSlider onChange={onChange} name={"Walk Length"} value={walkLength} setValue={setWalkLength} min={100} max={500} step={10} />
      <CustomSlider onChange={onChange} name={"Random Walk Per Node"} value={randomWalkPerNode} setValue={setRandomWalkPerNode} min={1} max={50} />
      <CustomSlider onChange={onChange} name={"Vector Size"} value={vectorSize} setValue={setVectorSize} min={2} max={10} scale={(val)=>2**val} />
      <CustomSlider onChange={onChange} name={"Window"} value={windowVal} setValue={setWindowVal} min={3} max={10} />
      <CustomSlider onChange={onChange} name={"Min Count"} value={minCount} setValue={setMinCount} min={0} max={5} />
      <CustomSlider onChange={onChange} name={"Workers"} value={workers} setValue={setWorkers} min={1} max={4} />
      <CustomSlider onChange={onChange} name={"Epochs"} value={epochs} setValue={setEpochs} min={1} max={10} />
    </div>
  )
}

function EmbeddingMetapathNode({ data }) {

  return (
    <CustomNode
      title={"Apply Metapath embedding with parameters"}
      data={data}
      dataLeft={["graph", "metapaths"]}
      dataRight={["vectors"]}
    />
  );
}

export {EmbeddingMetapathNode, EmbeddingMetapathSidebar};