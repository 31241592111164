import './custom-node.css';
import {  IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Delete, PlusOne, Remove } from '@mui/icons-material';

import CustomNode from './CustomNode';

function SelectMetapathComponent({nodeSelected, setNodes, index, labels}) {

  const handleChange = (event: SelectChangeEvent, idx) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) {
          node.data.options.metapaths[index][idx] = event.target.value;
          if (idx === 0) {
            node.data.options.metapaths[index][node.data.options.metapaths[index].length-1] = event.target.value;
          }
          if (idx === node.data.options.metapaths[index].length-1) {
            node.data.options.metapaths[index][0] = event.target.value;
          }
        }
        return node;
      })
    )
  };

  const onClick = (event, idxRef) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) {
          if (node.data.options.metapaths[index].length === 0) {
            node.data.options.metapaths[index] = ["Gene", "Gene"];
          }
          else {
            node.data.options.metapaths[index] = [ 
              ...node.data.options.metapaths[index].slice(0, idxRef+1),
              "Gene",
              ...node.data.options.metapaths[index].slice(idxRef+1),
            ];
          }
        }
        return node;
      })
    )
  };

  const removeClick = (event, idxRef) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id && node.data.options.metapaths[index].length > 2) {
          node.data.options.metapaths[index] = node.data.options.metapaths[index].filter((val, idx) => idxRef !== idx);
        }
        return node;
      })
    )
  };

  const deleteClick = (event) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) {
          node.data.options.metapaths = node.data.options.metapaths.filter((val, idx) => index !== idx);
        }
        return node;
      })
    )
  };
  

  return (
    <div style={{border: '1px solid', borderRadius: '8px', margin: '4px', padding: '4px'}}>
      <IconButton onClick={deleteClick}>
        <Delete/>
      </IconButton>
      {nodeSelected.data.options.metapaths[index].map((node, idx) => 
        <div key={idx}>
          <div>
            <Select value={node} onChange={(event) => handleChange(event, idx)}>
              {labels.map((val, key) => <MenuItem key={key} value={val}>{val}</MenuItem>)}
            </Select>
          </div>
          {(idx!==nodeSelected.data.options.metapaths[index].length-1) 
            ? <div>
                {(idx!==nodeSelected.data.options.metapaths[index].length-2)
                  ? <IconButton onClick={(event) => removeClick(event, idx+1)}>
                      <Remove/>
                    </IconButton>
                  : <></>
                }
                <IconButton onClick={(event) => onClick(event, idx)}>
                  <PlusOne/>
                </IconButton>
              </div>
            : <></>
          }
        </div>
      )}
    </div>
  )
}

function SelectMetapathSidebar({nodeSelected, setNodes, labels}) {

  const onClick = (event) => {
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeSelected.id) {
          node.data.options.metapaths = [ 
            ...node.data.options.metapaths,
            ["Gene", "Gene"]
          ];
        }
        return node;
      })
    )
  };

  return (
    <>
      <h4>Metapaths : </h4>
      {nodeSelected.data.options.metapaths.map((metapath, index) => 
        <SelectMetapathComponent 
          key={index}
          nodeSelected={nodeSelected} 
          setNodes={setNodes} 
          index={index} 
          labels={labels}
        />
      )}
      <IconButton onClick={onClick}>
        <PlusOne/>
      </IconButton>
    </>
  )

}


function TransformAddMetapathNode({ data }) {

  return (
    <CustomNode
      title={"Metapaths"}
      data={data}
      dataRight={["metapaths"]}
    />
  );
}

export {TransformAddMetapathNode, SelectMetapathSidebar};