

class QueryBuilder {
    private static nodes: Array<string> = [];

    public static addNode(nodeId: string) {
        this.nodes.push(nodeId);
    }

    public static setNodes(nodesId: Array<string>) {
        this.nodes = nodesId;
    }

    public static buildQuery(): string {
        if (this.nodes.length === 0) {
            return `CALL db.schema.visualization()`;
        }
        else {
            var query = `MATCH (n)-[r]-(m) WHERE `;
            this.nodes.forEach(nodeId => {
                query += `ID(n)=${nodeId} OR `;
            });
            query = query.slice(0, -4) + ` RETURN * LIMIT 100`;
            return query;
        }
    }

    public static buildUpdateQuery(nodeId: string): string {
        return `MATCH (n)-[r]-(m) WHERE ID(n)=${nodeId} RETURN * LIMIT 100`;
    }
}

export {QueryBuilder}
