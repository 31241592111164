import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Chip, Grid, Stack } from '@mui/material';
import { Neo4JDriver } from '../../../../services/neo4j';
import AsyncSelect from './SelectLabel';
import { QueryBuilder } from '../../../../services/query';
import { NeovisBuilder } from '../../../../services/neovis';

type SelectType = {
  value: number,
  label: string,
  labels: string[]
};

function FindEntityComponent() {
  const [localNodes, setLocalNodes] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [labelSelected, setLabelSelected] = useState<SelectType | null>(null);
  const [individualSelected, setIndividualSelected] = useState<SelectType | null>(null);
  const [formatOptions, setFormatOptions] = useState<any[]>([]);
  const [formatSelected, setFormatSelected] = useState<string | null>(null);
  const [resetFormatOptionsKey, setResetFormatOptionsKey] = useState<number>(0);

  const prevLabelSelected = useRef<SelectType | null>(null);

  const removeIndividual = (val: any) => {
    setLocalNodes(localNodes.filter((node: any) => node.label !== val));
  };

  const addLocalNode = (newNode: any) => {
    if (newNode === 'reset') {
      setLocalNodes([]);
      QueryBuilder.setNodes([]);
      NeovisBuilder.render();
    } else {
      setLocalNodes(oldNodes => [...oldNodes, newNode]);
    }
  };

  const sendNodesToViz = () => {
    QueryBuilder.setNodes(localNodes.map((node: any) => node.value));
    let neovis = NeovisBuilder.getNeovis();
    if (neovis) {
      neovis.renderWithCypher(QueryBuilder.buildQuery());
    }   
  };

  const handleLabelSearch = useCallback(async (inputValue: string) => {
    const labelOptions = labels.filter((label: any) => label.label.toLowerCase().includes(inputValue.toLowerCase()));
    setResetFormatOptionsKey(prevKey => prevKey + 1);
    return labelOptions;
  }, [labels]);

  const handleIndividualSearch = useCallback(async (inputValue: string) => {
    if (labelSelected) {
      if (formatSelected) {
        const results = await Neo4JDriver.searchIndividualsByLabel(labelSelected.label, inputValue, formatSelected);
        return results.map((result: any) => ({
          ...result,
          label: result[formatSelected] || result.label,
        }));
      }
    }
    return [];
  }, [labelSelected, formatSelected]);

  const handleFormatChange = useCallback(async (inputValue: string) => {
    const formatOptionsFiltered = formatOptions.filter((format: any) => 
      format.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setResetFormatOptionsKey(prevKey => prevKey + 1)
    return formatOptionsFiltered.map((format: any) => ({
      label: format.label,
      value: format.value,
    }));
  }, [formatOptions]); 

  useEffect(() => {
    Neo4JDriver.getLabels(setLabels);

    if (labelSelected && labelSelected !== prevLabelSelected.current) {
      Neo4JDriver.getPropertiesByLabel(labelSelected.label, (properties: any) => {
        setFormatOptions(properties);
      });
    }
    prevLabelSelected.current = labelSelected;
  }, [labelSelected]);

  return (
    <div className="Selector">
      <Grid container spacing={1}>
        {localNodes.map((node: any, idx) => 
          <Grid item key={idx}>
            <Chip
              label={`${node.label} : ${node.labels[0]}`}
              onDelete={() => removeIndividual(node.label)}
              variant="outlined"
            />    
          </Grid>
        )}
      </Grid>

      <div>
        <h3>Entity Type :</h3>
        <AsyncSelect
          loadOptions={handleLabelSearch}
          onChange={(event: any, value: any, reason: any) => { 
            if (reason === 'selectOption') {
              setLabelSelected(value);
            }
          }}
        />

        <h3>Entity Format :</h3>
        <AsyncSelect
          loadOptions={handleFormatChange}
          onChange={(event: any, value: any, reason: any) => { 
            if (reason === 'selectOption') {
              setFormatSelected(value.label);
            }
          }}
          resetKey={resetFormatOptionsKey}
        />

        <h3>Entity Selection :</h3>
        <AsyncSelect
          loadOptions={handleIndividualSearch}
          onChange={(event: any, value: any, reason: any) => reason === 'selectOption' ? setIndividualSelected(value) : null }
          resetKey={resetFormatOptionsKey}
        />

        <div style={{ margin: '5%' }}>
          <Stack 
            direction="row" 
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button 
              onClick={() => addLocalNode(individualSelected)}
              variant="outlined"
              color="success"
            >Add</Button>
            <Button 
              onClick={() => addLocalNode('reset')}
              variant="outlined"
              color="success"
            >Reset</Button>
            <Button
              onClick={() => sendNodesToViz()}
              variant="outlined"
              color="success"
            >Send</Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export { FindEntityComponent };
