import axios from "axios";
import { MarkerType, addEdge, getIncomers } from "reactflow";
import { edgeColors, nodeData, urlApi, cleanUrlApi } from "./data-node";

let id = 1;
const getId = () => `node_${id++}`;

const createPipeline = async (reactFlowInstance, nodes, edges, token, editNode) => {
    let nodeReturn = reactFlowInstance.getNode("final-node");

    if (nodeReturn.data.completed) return 'Already Completed';

    let heap = [nodeReturn];
    let heap_uuid = [];

    let memoizator = {};

    await axios.post(`${urlApi}/final/return`, null, { params: { token: token } }).then((val) => {
        heap_uuid.push(val.data);
        memoizator[val.data] = nodeReturn.id;
    });

    let previous_nodes = null, last_uuid = null, tmp_node = null;

    while (heap.length > 0) {

        tmp_node = heap.pop();

        previous_nodes = getIncomers(tmp_node, nodes, edges);
        last_uuid = heap_uuid.pop();

        for (let previous_node of previous_nodes) {

            var url = previous_node.data.URL;

            const data = previous_node.data.options;
            data.after_uuid = last_uuid;

            await axios.post(url, data, { params: { token: token } }).then((val) => {
                heap_uuid.push(val.data);
                memoizator[val.data] = previous_node.id;
            })
        }

        heap = [...heap, ...previous_nodes];
    }

    const ws = new WebSocket(`ws://${cleanUrlApi}/pipeline/start-query/ws`);
    ws.onopen = (event) => {
        ws.send(token);
    }
    ws.onmessage = (event) => {
        if (event.data instanceof Blob) {
            var blob = new Blob([event.data], { type: 'image/png' });
            var new_url = window.URL.createObjectURL(blob);
            editNode(nodeReturn.id, { isLoading: false, completed: true, result: new_url });
        }
        else if (event.data.length > 50) {
            result = JSON.parse(event.data);
        }
        else {
            let [nodeId, val] = event.data.split(";");
            let node = reactFlowInstance.getNode(memoizator[nodeId]);
            if (val === '0') editNode(node.id, { isLoading: true, completed: false });
            else if (val === '1') editNode(node.id, { isLoading: false, completed: true });
        }
    };
}

const getBgCol = (sel, com) => {
    if (sel && com) return '#336699';
    if (com) return '#6699cc';
    if (sel) return '#cccccc';
    return '#ffffff';
}


const getEdgeStyle = (edgeType) => {
    if (edgeType === 'flow')
        return {
            markerEnd: { type: MarkerType.ArrowClosed, color: "#000" },
            style: { strokeWidth: 2, stroke: "#000" }
        }
    else
        return {
            markerEnd: { type: MarkerType.Arrow, color: edgeColors[edgeType] },
            style: { strokeWidth: 1, stroke: edgeColors[edgeType] }
        }
}

const createMetapathExample = (position, setNodes, setEdges) => {

    let groupId = createNewNode("group-node", position, setNodes);

    let queryId = createNewNode("data-query-node", { x: 50, y: 50 }, setNodes, groupId);
    let data2GraphId = createNewNode("transform-data2graph-node", { x: 200, y: 50 }, setNodes, groupId);

    let metapathId = createNewNode("transform-add-metapath-node", { x: 50, y: 200 }, setNodes, groupId);
    let metapathEmbId = createNewNode("embedding-metapath-node", { x: 200, y: 150 }, setNodes, groupId);

    let tsneId = createNewNode("reduction-tsne-node", { x: 50, y: 300 }, setNodes, groupId);
    let plotId = createNewNode("visualisation-plot-node", { x: 50, y: 400 }, setNodes, groupId);

    createNewEdge(queryId, data2GraphId, 'rows', setEdges);
    createNewEdge(metapathId, metapathEmbId, 'metapaths', setEdges);
    createNewEdge(data2GraphId, metapathEmbId, 'graph', setEdges);
    createNewEdge(metapathEmbId, tsneId, 'vectors', setEdges);
    createNewEdge(tsneId, plotId, 'vectors-reduced', setEdges);
    createNewEdge(plotId, "final-node", 'image', setEdges);
}

const createNode2VecExample = (position, setNodes, setEdges) => {

    let groupId = createNewNode("group-node", position, setNodes);

    let queryId = createNewNode("data-query-node", { x: 50, y: 50 }, setNodes, groupId);
    let data2GraphId = createNewNode("transform-data2graph-node", { x: 200, y: 50 }, setNodes, groupId);
    let node2VecId = createNewNode("embedding-node2vec-node", { x: 50, y: 150 }, setNodes, groupId);
    let tsneId = createNewNode("reduction-tsne-node", { x: 50, y: 250 }, setNodes, groupId);
    let plotId = createNewNode("visualisation-plot-node", { x: 50, y: 350 }, setNodes, groupId);

    createNewEdge(queryId, data2GraphId, 'rows', setEdges);
    createNewEdge(data2GraphId, node2VecId, 'graph', setEdges);
    createNewEdge(node2VecId, tsneId, 'vectors', setEdges);
    createNewEdge(tsneId, plotId, 'vectors-reduced', setEdges);
    createNewEdge(plotId, "final-node", 'image', setEdges);
}

const createNewEdge = (source, target, type, setEdges) => {
    const newEdge = {
        id: getId(),
        source,
        target,
        sourceHandle: type,
        targetHandle: type,
        ...getEdgeStyle(type)
    };

    setEdges((eds) => eds.concat(newEdge));
    return newEdge.id;
}

const createNewNode = (nodeType, position, setNodes, parentNode = null) => {

    const { URL, data } = nodeData[nodeType];
    const newNode = {
        id: getId(),
        type: nodeType,
        position,
        parentNode,
        data: {
            URL: URL,
            options: data,
            completed: false,
            isLoading: false,
            selected: false
        },
        style: (nodeType === 'group-node')
            ? { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 600, height: 500, border: '1px solid #000' }
            : { backgroundColor: 'rgba(255, 255, 255, 1.0)', border: '1px solid #000' }
    };

    setNodes((nds) => nds.concat(newNode));
    return newNode.id;
}

const customAddEdge = (edgeParams, edges) => {
    if (edgeParams.sourceHandle !== edgeParams.targetHandle && edgeParams.targetHandle !== 'any') return edges;

    const s = getEdgeStyle(edgeParams.sourceHandle);
    return addEdge({
        ...edgeParams,
        ...s
    },
        edges)
}

let result: any = null;

export { result, getBgCol, getEdgeStyle, customAddEdge, createPipeline, createNewNode, createNewEdge, createNode2VecExample, createMetapathExample }
