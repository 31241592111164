import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, Button, CircularProgress, Card, CardContent, Typography, Box } from '@mui/material';
import { getCypherQuery } from '../../../services/ollama';
import { NeovisBuilder } from '../../../services/neovis';

function GraphQueryNaturalLanguageComponent() {
    const [prompt, setPrompt] = useState('');
    const [cypherQuery, setCypherQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [nodeInfo, setNodeInfo] = useState(null);
    const vizRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (vizRef.current) {
            NeovisBuilder.create(setNodeInfo);
        }
    }, []);

    const handleExecute = () => {
        // Execute the cypher query and update the graph
        if (cypherQuery) {
            NeovisBuilder.getNeovis().renderWithCypher(cypherQuery);
        }
    };

    const handleGetCypherQuery = async () => {
        console.log('Fetching Cypher query for prompt:', prompt);
        setLoading(true);
        try {
            let response = await getCypherQuery('reg', prompt);
            console.log('Received Cypher query:', response);

            if (!response.includes('LIMIT') && !response.includes("Not Query")) {
                response = response.replace(/;$/, '');
                response += ' LIMIT 25;';
            }

            console.log('Cypher query with LIMIT:', response);

            setCypherQuery(response);
        } catch (error) {
            console.error('Error fetching Cypher query:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="GraphQueryNaturalLanguageComponent">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h3>Enter your prompt to get a Cypher query</h3>
                    <TextField
                        id="prompt"
                        label="Prompt"
                        multiline
                        rows={4}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                    <Button 
                        variant="contained" 
                        onClick={handleGetCypherQuery} 
                        style={{ marginTop: '20px' }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Get Cypher Query'}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <h3>&nbsp;</h3>
                    <TextField
                        id="cypherQuery"
                        label="Cypher Query"
                        multiline
                        rows={4}
                        value={cypherQuery}
                        onChange={(e) => setCypherQuery(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                    <Button 
                        variant="contained" 
                        onClick={handleExecute} 
                        style={{ marginTop: '20px' }}
                        disabled={loading}
                    >
                        Execute
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={9}>
                    <div 
                        ref={vizRef}
                        id="viz" 
                        style={{ backgroundColor: '#ebeff5', height: '700px', border: '1px solid black' }}
                    ></div>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{ height: '700px', overflow: 'auto' }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Node Information
                            </Typography>
                            {nodeInfo && nodeInfo.raw && nodeInfo.raw.properties ? (
                                <Box mt={2}>
                                    {Object.entries(nodeInfo.raw.properties).map(([key, value]) => (
                                        <Box key={key} mb={1}>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {key}
                                            </Typography>
                                            <Typography variant="body2">
                                                {JSON.stringify(value, null, 2)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Typography variant="body2" color="textSecondary">
                                    Click on a node to see its information.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export { GraphQueryNaturalLanguageComponent }
