import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';

export default function AsyncSelect({ loadOptions, onChange, resetKey }: any) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (resetKey) {
      setOptions([]);
    }
  }, [resetKey]);

  useEffect(() => {
    if (open && options.length === 0) {
      (async () => {
        setLoading(true);
        const initialOptions = await loadOptions('');
        setOptions(initialOptions);
        setLoading(false);
      })();
    }
  }, [open, loadOptions, options.length]);

  const handleInputChange = async (event: any, value: string) => {
    setLoading(true);
    const newOptions = await loadOptions(value);
    setOptions(newOptions);
    setLoading(false);
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
      getOptionLabel={(option: any) => option.label}
      options={options}
      onChange={onChange}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Asynchronous"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
