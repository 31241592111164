import './custom-node.css';

import CustomNode from './CustomNode';

function ReductionTSNENode({ data }) {

  return (
    <CustomNode
      title={"Apply TSNE Reduction to previous embedding with parameters"}
      data={data}
      dataLeft={["vectors"]}
      dataRight={["vectors-reduced"]}
    />
  );

}

export {ReductionTSNENode};