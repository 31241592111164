import './custom-node.css';

import CustomNode from './CustomNode';

function VisualisationPlotNode({ data }) {
  return (
    <CustomNode
      title={"Plot visualisation of reduced embedding with parameters"}
      data={data}
      dataLeft={["vectors-reduced"]}
      dataRight={["image"]}
    />
  );

}

export {VisualisationPlotNode};