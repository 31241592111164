import './custom-node.css';

import CustomNode from './CustomNode';

function TransformToGraphSidebar(props: any) {

  return (
    <div style={{padding: "10px"}}>
      <h4>Convert rows to a graph</h4>
      <p>Input rows</p>
      <p>Output graph</p>
    </div>
  )
}


function TransformToGraphNode({ data }) {

  return (
    <CustomNode
      title={"Convert query output to graph"}
      data={data}
      dataLeft={["rows"]}
      dataRight={["graph"]}
    />
  );

}

export {TransformToGraphSidebar, TransformToGraphNode};