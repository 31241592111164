import { Box, Button, Divider, Grid, IconButton, MenuItem, MenuList, Paper, styled, Tooltip, TextField, FormGroup, FormControlLabel, Select, Switch } from "@mui/material"
import { useState, useEffect } from "react";
import {Add, Delete} from '@mui/icons-material';
import { Tree, Node } from "./query-visu";
import { Neo4JDriver } from "../../../services/neo4j";



// SPARNATURAL

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    display: 'flex'
}));

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textTransform: 'none',
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
}));



function Selector({options, open, switchState, refreshLabel, value}: any) {

    const handleSelected = (event) => {
        refreshLabel(event.target.outerText)
        switchState();
    };

    return (
        <>
        {value === '' && open ?
            <div style={{zIndex: 10}}>
                <Paper sx={{width: '120px', overflow: 'auto', whiteSpace: 'nowrap'}}>
                    <MenuList>
                        {
                            !options ? <></>
                            : options.map((option, idx) => 
                                <MenuItem key={idx} onClick={handleSelected}>{option.name}</MenuItem>
                            )
                        }
                    </MenuList>
                </Paper> 
            </div>
            : <Div> {value} </Div>
        }
        </>
    )

}

const LineComponent = ({node, idx, refreshParent}) =>  {

    const [currentState, setCurrentState] = useState(
        node.labelSubject === '' ? 0 : 1
    );
    const openStateL = Boolean(currentState===0);
    const openStateM = Boolean(currentState===1);
    const openStateR = Boolean(currentState===2);

    const addLine = Boolean(currentState===3);

    const dispAddLine = Boolean(currentState>=3);

    useEffect(() => {
        if (addLine) {
            switchState();
        }
    }, [addLine]);

    const setLblSub = (label: string) => {
        node.labelSubject = label;
    }
    const setLblRel = (label: string) => {
        node.labelRelation = label;
    }
    const setLblObj = (label: string) => {
        node.labelObject = label;
    }

    const reset = () => {
        if (node.parent.root && node.parent.children.length === 1) {
            setLblSub('');
            setCurrentState(0);
        }
        else setCurrentState(1);

        setLblRel('');
        setLblObj('');

        node.emptySubTree();

        refreshParent();
    }

    const handleDeleteLine = () => {
        if (node.parent.root && node.parent.children.length === 1) reset();
        else {
            tree.removeNode(node.id);
            if (node.parent.children.length === 0) node.parent.selector = ['selector'];
        }
        refreshParent();
    }

    const newChild = () => {
        node.addChild();
        switchState();
        refreshParent();
    }

    const newSibling = () => {
        node.addSibling();
        switchState();
        refreshParent();
    }

    const switchState = () => {
        setCurrentState(i => i + 1);
    }

    return  <>
        <Grid item key={2*idx} xs={6} md={12-node.size}>
        </Grid>
        <Grid item key={2*idx+1} xs={6} md={node.size} textAlign={'left'}>
            <div style={{margin:'10px'}}>


                <Item sx={{position:'relative'}}>          
                    <Selector 
                        options={node.getSubLabels()}
                        open={openStateL}
                        switchState={switchState}
                        refreshLabel={setLblSub}
                        value={node.labelSubject}
                    />
                    {dispAddLine ? <Button sx={{position:'absolute', left:'0%', top:'100%'}} onClick={newSibling}>AND</Button> : null}
                    <Divider orientation="vertical" flexItem />
                    <Selector 
                        options={node.getRelLabels()}
                        open={openStateM}
                        switchState={switchState}
                        refreshLabel={setLblRel}
                        value={node.labelRelation}
                    />
                    <Divider orientation="vertical" flexItem />
                    <Selector
                        options={node.getObjLabels()}
                        open={openStateR}
                        switchState={switchState}
                        refreshLabel={setLblObj}
                        value={node.labelObject}
                    />
                    <Tooltip title="Delete" placement="top-end">
                        <IconButton onClick={reset}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    
                    <Button
                        onClick={handleDeleteLine}
                    >x</Button>
                    <Switch onChange={() => node.setOptional()} value={node.optional}/>

                </Item>  
                
                {
                    (node.children.length===0) && dispAddLine ?
                        <FormGroup>
                            <FormControlLabel 
                                label="Find any : " 
                                control={
                                    <>
                                        <Select 
                                            label="DataProperty"
                                            defaultValue={0}
                                        >
                                            {node.getDataProperty().map((dataProp, idx) => 
                                                <MenuItem value={idx}>{dataProp.name} : {dataProp.type}</MenuItem>    
                                            )}
                                        </Select>
                                        <TextField size="small" variant="outlined" />
                                    </>
                                } 
                                labelPlacement="start"
                            />
                            { Neo4JDriver.startToRel[node.labelObject] ? 
                            <FormControlLabel 
                                label="Find where : ... " 
                                control={<IconButton onClick={newChild} size="small"><Add fontSize="inherit" /></IconButton>} 
                                labelPlacement="start"
                            /> : null
                            }
                            
                        </FormGroup>
                        : null
                }
            </div>
        </Grid>
    </>
}

const tree: Tree = new Tree();

function SPARQLQBComponent() {

    const [key, setKey] = useState(1);
    const [query, setQuery] = useState("");

    const refreshParent = () => {
        setKey(key => key + 1);
    }

    const renderTree = (node: Node) => {
        return <>
            <LineComponent
                node={node}
                idx={node.id}
                refreshParent={refreshParent}
            />
            {node.children.map((child) => renderTree(child))}
        </>
    }

    const createQuery = () => {
        setQuery(tree.toCYPHER());
    }

    return <>
            <Box sx={{ flexGrow: 1 }}>

                <Grid container spacing={0} justifyContent="flex-end">
                    {tree.children.map((child) => renderTree(child))}
                </Grid>

            </Box>
            <Button onClick={createQuery}>Load Query</Button>
            <pre style={{whiteSpace: 'break-spaces', textAlign: 'left'}}>{query}</pre>
        </>
}

export {SPARQLQBComponent}