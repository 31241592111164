import './Main.css';
import { useState, SyntheticEvent } from 'react';
import { Tab, Tabs } from '@mui/material';
import { NeoComponent, TabPanel } from './neovis/NeovisComponent';
import { SPARQLQBComponent } from './queryuserfriendly/SPARQLQBComponent';
import DiseaseGeneComponent from './diseases-genes/DiseaseGeneLink';
import PipelineComponent from './pipeline/PipelineComponent';
import MultiAgentSystemComponent from './multi-agent-system/MultiAgentSystemComponent';
import { ResultComponent } from './result/ResultComponent';
import { GraphQueryNaturalLanguageComponent } from './graph-query-nl/GraphQueryNaturalLanguageComponent';

function Main() {

    const [mainTab, setMainTab] = useState(0);

    const handleMainChange = (event: SyntheticEvent, newValue: number) => {
        setMainTab(newValue);
    };

    return (
        <div className="Main">
            <div className="Box">
                <Tabs value={mainTab} onChange={handleMainChange} centered>
                    <Tab label="Graph Visu" />
                    <Tab label="Maladies" />
                    <Tab label="Graph Query" />
                    <Tab label="Graph Query NL" />
                    <Tab label="Système multi-agents" />
                    <Tab label="Pipeline" />
                    <Tab label="Result" />
                </Tabs>
                <TabPanel value={mainTab} index={0}>
                    <NeoComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={1}>
                    <DiseaseGeneComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={2}>
                    <SPARQLQBComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={3}>
                    <GraphQueryNaturalLanguageComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={4}>
                    <MultiAgentSystemComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={5}>
                    <PipelineComponent />
                </TabPanel>
                <TabPanel value={mainTab} index={6}>
                    <ResultComponent />
                </TabPanel>
            </div>
        </div>
    );
}

export { Main };
