import React from 'react';

import sma_legend_png from '../../../assets/sma_legend.png';
import complexe_rapide_mov from '../../../assets/complexe_rapide.webm';
import simple_lente_mov from '../../../assets/simple_lente.webm';

import './MultiAgentSystem.css'; // Assuming you have some basic CSS for styling

const MultiAgentSystemComponent: React.FC = () => {
    return (
        <div className="container">
            <div className="container-row">
                <div className="left">
                    <img src={sma_legend_png} alt="SMA Legend" className="legend-image" />
                </div>
                <div className="right">
                    <video src={simple_lente_mov} controls autoPlay loop className="video" />
                    <video src={complexe_rapide_mov} controls autoPlay loop className="video" />
                </div>
            </div>
            <div className="bottom">
                <h2>Lexique</h2>
                <p><strong>Type de protéine</strong>: Types de protéine simulées</p>
                <p><strong>Lien</strong>: Relations entre les protéines</p>
                <p><strong>Valeurs idéales</strong>: Valeurs idéales des paramètres de l’environnement pour permettre au mieux cette connexion.</p>
                <p><strong>Quantité de phéromones</strong>: Quantité de phéromones déposées par les agents sur ce lien. Plus il y a de phéromones, plus les agents vont suivre ce lien.</p>
                <p><strong>Paramètres de l’environnement</strong>: État général de l’environnement</p>
                <p><strong>Agent</strong>: Agent qui aura pour but de se déplacer jusqu’à atteindre un phénotype</p>
                <p><strong>Actions</strong>: Actions possibles sur la simulation</p>
                <h2>Fonctionnement de la simulation</h2>
                <p>Pour créer cette simulation, nous donnons au programme un fichier JSON, permettant de configurer celle-ci. Dans ce fichier JSON, nous allons définir:</p>
                <ul>
                    <li>La simulation:
                        <ul>
                            <li>Quantité d’étapes à simuler</li>
                            <li>Vitesse de la simulation</li>
                            <li>Affichage ou non de la simulation</li>
                            <li>Les propriétés internes de l’environnement</li>
                        </ul>
                    </li>
                    <li>La liste des protéines à simuler:
                        <ul>
                            <li>La quantité de chaque protéine</li>
                            <li>Le taux d’activation de départ</li>
                            <li>Le taux d’activation à atteindre pour considérer l’ensemble comme actif</li>
                        </ul>
                    </li>
                    <li>Les paramètres des connexions:
                        <ul>
                            <li>La liste des paramètres ayant un impact sur la connexion</li>
                            <li>La valeur par idéale de chaque paramètre</li>
                            <li>La courbe d’activation du paramètre</li>
                        </ul>
                    </li>
                    <li>Les agents à impliquer:
                        <ul>
                            <li>Le type de l’agent</li>
                            <li>La quantité des agents</li>
                            <li>La position de départ</li>
                            <li>La durée de vie</li>
                        </ul>
                    </li>
                </ul>
                <p>Au lancement, nous préparons la simulation en fonction des paramètres du fichier. À partir de ce moment, la simulation va se dérouler ainsi:</p>
                <h4>A chaque étape de la simulation:</h4>
                <ul>
                    <li>Pour chaque agent:
                        <ul>
                            <li>S’il est en mode « exploration »:
                                <ul>
                                    <li>Sélectionne une entité « EntiteSource », sur le nœud sur lequel il est</li>
                                    <li>Sélectionne un lien « Link » de sortie</li>
                                    <li>Sélectionne une entité « EntiteTarget », sur la cible du lien sélectionné</li>
                                    <li>Se met en attente le temps que tous les agents soient prêts</li>
                                    <li>Tente une interaction « Link » entre « EntiteSource » et « EntiteTarget »</li>
                                    <li>Se met en attente le temps que tous les agents aient tentés</li>
                                    <li>Si la connexion a réussi:
                                        <ul>
                                            <li>Déplacer l’agent de la source à la cible</li>
                                            <li>Ajouter le déplacement dans l’historique de l’agent</li>
                                            <li>Si le nouveau nœud est un phénotype:
                                                <ul>
                                                    <li>Passer en mode « Retour »</li>
                                                    <li>Récupère la qualité du phénotype</li>
                                                    <li>Si l’historique a une taille supérieure à deux:
                                                        <ul>
                                                            <li>Exporter l’historique dans un fichier</li>
                                                        </ul>
                                                    </li>
                                                    <li>Sinon:
                                                        <ul>
                                                            <li>Tuer l’agent</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Sinon:
                                                <ul>
                                                    <li>Ne rien faire</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Se met en attente le temps que tous les agents aient terminés</li>
                            <li>S’il est en mode « retour »:
                                <ul>
                                    <li>Sélectionne et retire le dernier lien de l’historique</li>
                                    <li>Ajoute une quantité de phéromones (liée à la qualité du phénotype) sur le lien</li>
                                    <li>Déplace l’agent de sa position actuelle jusqu’à la source du lien</li>
                                    <li>Si l’historique est vide:
                                        <ul>
                                            <li>Passer en mode « Exploration »</li>
                                            <li>Diminuer le nombre de points de vie</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>Vérifier l’état général de la simulation:
                        <ul>
                            <li>Enregistrer l’état de chaque protéine dans un fichier</li>
                            <li>Si un phénotype est actif ou, si nous avons atteint le nombre d’étape à simuler:
                                <ul>
                                    <li>Stopper la simulation</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr
                    style={{
                        color: "black",
                        backgroundColor: "black",
                        height: 5
                    }}
                />
                <h4>Choisir une entité:</h4>
                <p>Le choix des entités à tenter de connecter se fait de manière aléatoire</p>
                <h4>Choisir un lien:</h4>
                <p>Le choix du lien à pour sortir du nœud actuel se fait en fonction du type de l’agent. Plusieurs types d’agents ont été développés, avec des comportements différents:</p>
                <ul>
                    <li><strong>Random Agent:</strong>
                        <ul>
                            <li>L’agent choisira aléatoirement sa sortie</li>
                            <li><strong>Avantages:</strong>
                                <ul>
                                    <li>L’agent aléatoire a pour avantage de suivre les connaissances acquises. Il suit uniquement des liens existants. Il va apporter de la nouveauté dans les chemins suivis.</li>
                                </ul>
                            </li>
                            <li><strong>Inconvénients:</strong>
                                <ul>
                                    <li>Il n’est pas influencé par les autres agents, par l’environnement ou par l’algorithme</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Ant Agent:</strong>
                        <ul>
                            <li>L’agent choisira la sortie avec la quantité de phéromones la plus importante;</li>
                            <li><strong>Avantages:</strong>
                                <ul>
                                    <li>Cet agent va être influencé par les autres agents. Plus un chemin sera pris, plus il aura de chance d’être suivi de nouveau.</li>
                                </ul>
                            </li>
                            <li><strong>Inconvénients:</strong>
                                <ul>
                                    <li>Aura tendance à ne suivre que le chemin le plus court, sans explorer les autres chemins du graphe</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Environmental Agent:</strong>
                        <ul>
                            <li>L’agent choisira la sortie la plus logique en fonction des paramètres de l’environnement</li>
                            <li><strong>Avantages:</strong>
                                <ul>
                                    <li>Il est influencé par les conditions environnementales du système et par conséquent, peut se rapprocher du fonctionnement biologique réel.</li>
                                </ul>
                            </li>
                            <li><strong>Inconvénients:</strong>
                                <ul>
                                    <li>Nous possédons très peu d’informations au sujet de l’environnement et des conditions optimales à atteindre pour améliorer les connexions</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Ant Environmental Agent:</strong>
                        <ul>
                            <li>L’agent choisira la sortie la plus logique en fonction des paramètres de l’environnement, modulé par la quantité de phéromones sur les liens;</li>
                            <li><strong>Avantages:</strong>
                                <ul>
                                    <li>Peut être influencé à la fois par les autres agents et par l’environnement, il aura donc tendance à trouver les chemins les plus court, pour un certain environnement</li>
                                </ul>
                            </li>
                            <li><strong>Inconvénients:</strong>
                                <ul>
                                    <li>Comme pour l’Environmental Agent, le manque d’information limite son fonctionnement. Comme pour l’Ant Agent, il aura tendance à explorer des voies très suivies et donc pas forcément les plus intéressantes.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Tenter une interaction:</h4>
                <p>Cela veut dire activer ou désactiver. Nous partons sur ces deux principes simples, puisqu’ils permettent un fonctionnement booléen de notre système. Cependant, une simple mise à jour de l’ontologie et du comportement des agents permettrait d’explorer des fonctionnements biologiques plus complexes.</p>
                <p>Pour le moment, une entité source « Active » peut « activer » ou « désactiver » (selon le lien sélectionné) une entité cible. Si l’entité source est inactive, elle ne peut rien faire</p>
            </div>
        </div>
    );
}

export default MultiAgentSystemComponent;
