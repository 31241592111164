import { Handle, Position } from 'reactflow';
import './custom-node.css';

import TableRowsIcon from '@mui/icons-material/TableRows';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import PolylineIcon from '@mui/icons-material/Polyline';
import RouteIcon from '@mui/icons-material/Route';
import AppsIcon from '@mui/icons-material/Apps';
import { edgeColors } from '../data-node';
import { LinearProgress } from '@mui/material';
import { getBgCol } from '../utils';


const edgeIcons = {
    "rows": <TableRowsIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />,
    "graph": <PolylineIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />,
    "metapaths": <RouteIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />,
    "vectors": <AppsIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />,
    "vectors-reduced": <DragIndicatorIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />,
    "image": <ScatterPlotIcon style={{ width: 12, height: 12, pointerEvents: "none" }} />
}


const getStyle = (edgeType, position, i) => {
    return {
        top: 40+i*30, 
        background: edgeColors[edgeType],
        right: (position===Position.Left) ? null : '-5px',
        left: (position===Position.Right) ? null : '-5px',
        minWidth: 20,
        height: 20,
        borderRadius: 4,
        placeItems: "center",
        display: "grid",
        color: "#fff",
        zIndex: 2
    }
};

function CustomHandle({ edgeType, position, index }) {

    return (
        <Handle 
          type={(position===Position.Left) ? "target" : "source"} 
          position={position} 
          style={getStyle(edgeType, position, index)} 
          id={edgeType}
        > 
          {edgeIcons[edgeType]}
        </Handle>
    );
}

function CustomNode({
    title,
    data,
    dataLeft=[],
    dataRight=[],
    isGroupNode=false
}) {

  return (
    <div className="custom-node" style={!isGroupNode ? {backgroundColor: getBgCol(data.selected, data.completed)} : {}}>
      <div>{title}</div>
      <div style={{height: 50}}>
        {dataLeft.map((val, index) => <CustomHandle key={index} index={index} edgeType={val} position={Position.Left}/>)}
        {(!isGroupNode && data.isLoading)? <LinearProgress />: <></>}
        {dataRight.map((val, index) => <CustomHandle key={index} index={index} edgeType={val} position={Position.Right}/>)}
      </div>
    </div>
  );
}

export default CustomNode;