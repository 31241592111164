
const urlApi = process.env.REACT_APP_DOCKERENV ? "https://api.ihm.aidd4h.checksem.fr" : "http://127.0.0.1:8000"
const cleanUrlApi = process.env.REACT_APP_DOCKERENV ? "api.ihm.aidd4h.checksem.fr" : "127.0.0.1:8000"
// const urlApi = "127.0.0.1:8000"

const nodeCategories = [
    { category: "Data", options: ["data-query-node"] },
    { category: "Transform", options: ["transform-data2graph-node", "transform-add-metapath-node"] },
    { category: "Embeddings", options: ["embedding-node2vec-node", "embedding-metapath-node"] },
    { category: "Reduction", options: ["reduction-tsne-node"] },
    { category: "Visualisation", options: ["visualisation-plot-node"] },
    { category: "Example", options: ["node2vec-example", "metapath-example"] }
]

const nodeData = {
    "data-query-node": { name: "Query Node", URL: `${urlApi}/data/query`, data: { query: ["BiochemicalReaction", "Gene"] } },
    "transform-data2graph-node": { name: "Convert to graph Node", data: {}, URL: `${urlApi}/transform/data-to-graph` },
    "transform-add-metapath-node": { name: "Add Metapath to Pipeline Node", data: { metapaths: [["BiochemicalReaction", "Gene", "BiochemicalReaction"]] }, URL: `${urlApi}/transform/add-metapath` },
    "embedding-node2vec-node": { name: "Node2Vec Node", data: {}, URL: `${urlApi}/embeddings/node2vec` },
    "embedding-metapath-node": { name: "Metapath Node", data: {}, URL: `${urlApi}/embeddings/metapath` },
    "reduction-tsne-node": { name: "TSNE Node", data: {}, URL: `${urlApi}/reduction/tsne` },
    "visualisation-plot-node": { name: "Plot Node", data: {}, URL: `${urlApi}/visualisation/plot` },
    "node2vec-example": { name: "Node2Vec Example Node", data: null, URL: null },
    "metapath-example": { name: "Metapath Example Node", data: null, URL: null },
    "group-node": { name: "Node2Vec Example Node", data: null, URL: null },
}

const edgeColors = {
    "rows": '#f00',
    "graph": '#00f',
    "metapaths": '#f0f',
    "vectors": '#0f0',
    "vectors-reduced": '#a59',
    "image": '#0ff'
}

const initialNodes = [{
    id: "final-node",
    type: "final-return-node",
    position: { x: 1000, y: 500 },
    data: { URL: "${urlApi}/final/return", options: {}, selected: false, isLoading: false, completed: false },
    style: { backgroundColor: '#ffffff', border: '1px solid #000' },
    draggable: false
}];

const initialEdges = [];

export { cleanUrlApi, urlApi, nodeData, nodeCategories, edgeColors, initialEdges, initialNodes };
