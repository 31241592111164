import { useEffect, useState } from "react";
import { NeovisBuilder } from "../../../services/neovis";
import { FindEntityComponent } from "./selectindividuals/FindEntityComponent";
import { NodeInfoComponent } from "./selectindividuals/NodeInfoComponent";
import { Box, Typography } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function NeoComponent() {

    const [nodeInfo, setNodeInfo] = useState(null);

    useEffect(() => {
        NeovisBuilder.create(setNodeInfo);
    }, []);

    return (
        <div style={{display: 'flex'}}>
            <div style={{width: '70%', position: 'relative'}}>
                <div id="viz" style={{width: "100%", height: "700px", backgroundColor: '#F5F5F5'}}/>
            </div>
            <div style={{width: '30%', position: 'relative'}}>
                    <FindEntityComponent />
                    {
                        nodeInfo ? <NodeInfoComponent nodeInfo={nodeInfo} />
                        : <></>
                    }
            </div>
        </div>
    );

}

export {NeoComponent, TabPanel};