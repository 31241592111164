import axios from 'axios';

const API_URL: string = process.env.REACT_APP_DOCKERENV ? "https://api.ihm.aidd4h.checksem.fr/llm/get_cypher_query" : 'http://localhost:8000/llm/get_cypher_query';

export const getCypherQuery = async (token: string, query: string): Promise<string> => {
    const response = await axios.get<string>(API_URL, {
        params: { token, query }
    });
    return response.data;
};
