import { result } from "../pipeline/utils"
import Plot from 'react-plotly.js';

function ResultComponent () {

    return <>
        {
            (result===null)
            ? <h1>Aucun Résultat</h1>
            :   <>
                    <Plot data={result.data} layout={result.layout} />
                </>
        }
    </>
}

export {ResultComponent}