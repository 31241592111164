import { Autocomplete, Button, Collapse, Divider, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Box } from "@mui/system";
import { useState } from "react"
import { Neo4JDriver } from "../../../services/neo4j";


function colorInterpolation(score: number) {
    return `
    rgb(${(1-score)*255}, ${(1-score)*173+82}, ${(1-score)*102+153})
    `;
}

function RowDisgenet(props: any) {
    const { row } = props;
    const [open, setOpen] = useState(false);
  
    return <>
        <TableRow onClick={() => setOpen(!open)} 
            sx={{ 
                '&:hover': {
                    backgroundColor: '#EEEEEE', 
                    cursor: 'pointer'
                }, 
                textAlign: 'center', 
                '& > *': {
                    padding: '1px 1px',
                    color: '#5A5F5F',
                } 
            }}
        >
            <TableCell component="th" scope="row" sx={{borderBottom: 0}}>
                {row.disease_id}
            </TableCell>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.disease_name}</TableCell>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score_C/0.6) }} title={row.score_C}/>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score_M/0.2) }} title={row.score_M}/>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score_L/0.1) }} title={row.score_L}/>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score_I/0.1) }} title={row.score_I}/>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score) }} title={row.score}/>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.disgenet_id}</TableCell>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.depth}</TableCell>
        </TableRow>
    </>
}

function RowOpenTarget(props: any) {
    const { row } = props;
    const [open, setOpen] = useState(false);
  
    return <>
        <TableRow onClick={() => setOpen(!open)} sx={{ '&:hover': {backgroundColor: '#EEEEEE', cursor: 'pointer'}, textAlign: 'center'}}>
            <TableCell component="th" scope="row" sx={{borderBottom: 0}}>
                {row.disease_id}
            </TableCell>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.disease_name}</TableCell>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.gene_id}</TableCell>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.gene_name}</TableCell>
            <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score) }} title={row.score}/>
            <TableCell align="right" sx={{borderBottom: 0}}>{row.depth}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Scores
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Affected Pathway</TableCell>
                                        <TableCell align="left">Animal Model</TableCell>
                                        <TableCell align="left">Genetic Association</TableCell>
                                        <TableCell align="left">Known Drug</TableCell>
                                        <TableCell align="left">Literature</TableCell>
                                        <TableCell align="left">RNA Expression</TableCell>
                                        <TableCell align="left">Somatic Mutation</TableCell>
                                        <TableCell align="left">Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{height: '4vh'}}>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.affected_pathway) }} title={row.affected_pathway}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.animal_model) }} title={row.animal_model}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.genetic_association) }} title={row.genetic_association}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.known_drug) }} title={row.known_drug}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.literature) }} title={row.literature}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.rna_expression) }} title={row.rna_expression}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.somatic_mutation) }} title={row.somatic_mutation}/>
                                        <TableCell align="right" sx={{borderBottom: 0, border: '1px solid #eeefef'}} style={{backgroundColor: colorInterpolation(row.score) }} title={row.score}/>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

function DiseaseGeneComponent() {
    

    const [rowsDisgenet, setRowsDisgenet] = useState([]);
    const [rowsOpenTarget, setRowsOpenTarget] = useState([]);

    const [diseaseName, setDiseaseName] = useState('ovarian cancer');
    const [geneSymbol, setGeneSymbol] = useState('FOLR1');

    const [hintDisease, setHintDisease] = useState([]);
    const [hintGene, setHintGene] = useState([]);

    const [openAutocompleteDisease, setOpenAutocompleteDisease] = useState(false);
    const [openAutocompleteGene, setOpenAutocompleteGene] = useState(false);

    const handleDisease = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (!openAutocompleteDisease) {
            setOpenAutocompleteDisease(true)
        }
        if (value.length === 3) {
            Neo4JDriver.hintDisease(value, setHintDisease);
            setOpenAutocompleteDisease(true);
        }
        setDiseaseName(value);
    }

    const handleGene = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (!openAutocompleteGene) {
            setOpenAutocompleteGene(true)
        }
        if (value.length === 3) {
            Neo4JDriver.hintGene(value, setHintGene);
            setOpenAutocompleteGene(true);
        }
        setGeneSymbol(value);
    }

    const callQuery = () => {
        Neo4JDriver.findByDiseasesAndGenes(diseaseName, geneSymbol, setRowsDisgenet, setRowsOpenTarget);
    }

    const downloadDisgenet = () => {
        let dis_csv = "data:text/csv;charset=utf-8,";
        dis_csv += Object.keys(rowsDisgenet[0]).join(";")+"\r\n";

        rowsDisgenet.forEach((rowArray) => {
            let row = Object.values(rowArray).join(";");
            dis_csv += row + "\r\n";
        });
        
        var dis_link = document.createElement("a");
        dis_link.setAttribute("href", encodeURI(dis_csv));
        dis_link.setAttribute("download", "dis_data.csv");

        dis_link.click();
    }

    const downloadOpenTarget = () => {
        let ot_csv = "data:text/csv;charset=utf-8,";
        ot_csv += Object.keys(rowsOpenTarget[0]).join(";")+"\r\n";

        rowsOpenTarget.forEach((rowArray) => {
            let row = Object.values(rowArray).join(";");
            ot_csv += row + "\r\n";
        });

        var ot_link = document.createElement("a");
        ot_link.setAttribute("href", encodeURI(ot_csv));
        ot_link.setAttribute("download", "ot_data.csv");

        ot_link.click();
    }

    return  <>

        <Paper
            component="form"
            sx={{ m: '1% 25%', p: '2px 4px', display: 'flex', alignItems: 'center', width: '50%' }}
        >
            <Autocomplete
                freeSolo
                sx={{ ml: 1, flex: 1 }}
                options={hintDisease}
                open={openAutocompleteDisease}
                getOptionLabel={(option) => option.name}
                onInputChange={handleDisease}
                onClose={() => setOpenAutocompleteDisease(false)}
                renderInput={(params) => {
                    const {InputLabelProps, InputProps, ...rest} = params;
                    return <InputBase 
                        {...params.InputProps} 
                        {...rest} 
                        placeholder="Search Disease"
                        value={diseaseName}
                    />
                }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <Autocomplete
                freeSolo
                sx={{ ml: 1, flex: 1 }}
                options={hintGene}
                open={openAutocompleteGene}
                getOptionLabel={(option) => option.name}
                onInputChange={handleGene}
                onClose={() => setOpenAutocompleteGene(false)}
                renderInput={(params) => {
                    const {InputLabelProps, InputProps, ...rest} = params;
                    return <InputBase 
                        {...params.InputProps} 
                        {...rest} 
                        placeholder="Search Gene"
                        value={geneSymbol}
                    />
                }}
            />

            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={callQuery}>
                <SearchIcon/>
            </IconButton>
        </Paper>

        <Button onClick={downloadOpenTarget}>
            Download OpenTarget
        </Button>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                <TableHead>
                <TableRow>
                    <TableCell>Disease ID</TableCell>
                    <TableCell align="right">Disease Name</TableCell>
                    <TableCell align="right">Gene ID</TableCell>
                    <TableCell align="right">Gene Name</TableCell>
                    <TableCell align="right">Score</TableCell>
                    <TableCell align="right">Depth</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rowsOpenTarget.map((row, index) => (
                    <RowOpenTarget key={index} row={row}/>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

        <br />
        <Button onClick={downloadDisgenet}>
            Download Disgenet
        </Button>

        <TableContainer component={Paper}  sx={{ '& > *' : {borderBottom: 0 } }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                <TableHead>
                <TableRow>
                    <TableCell>Disease ID</TableCell>
                    <TableCell align="right">Disease Name</TableCell>
                    <TableCell align="right">Score C</TableCell>
                    <TableCell align="right">Score M</TableCell>
                    <TableCell align="right">Score L</TableCell>
                    <TableCell align="right">Score I</TableCell>
                    <TableCell align="right">Score</TableCell>
                    <TableCell align="right">Inferred from (ID)</TableCell>
                    <TableCell align="right">Depth</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rowsDisgenet.map((row, index) => (
                    <RowDisgenet key={index} row={row}/>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

    </>
}


export default DiseaseGeneComponent