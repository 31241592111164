import * as Neo4jTypes from 'neo4j-driver';

class NeoCredentials {
    private static url: string = process.env.REACT_APP_DOCKERENV ? "bolt://37.187.226.130:7688" : 'bolt://localhost:7687';
    private static database: string = process.env.REACT_APP_DOCKERENV ? "neo4j" : 'neo4j';
    private static username: string = process.env.REACT_APP_DOCKERENV ? "neo4j" : 'neo4j';
    private static password: string = process.env.REACT_APP_DOCKERENV ? "FcxwVs#3bw@cr3u" : 'password';

    public static getUrl() { return this.url }
    public static getDatabase() { return this.database }
    public static getUsername() { return this.username }
    public static getPassword() { return this.password }

    public static setUrl(url: string) { this.url = url; Neo4JDriver.resetDriver(); }
    public static setDatabase(database: string) { this.database = database; Neo4JDriver.resetDriver(); }
    public static setUsername(username: string) { this.username = username; Neo4JDriver.resetDriver(); }
    public static setPassword(password: string) { this.password = password; Neo4JDriver.resetDriver(); }

}

class Neo4JDriver {
    private static driver?: Neo4jTypes.Driver;

    public static startToRel = {};
    public static relToEnd = {};
    public static labels = [];

    private constructor() { };

    public static resetDriver() {
        this.driver = undefined;
    }

    public static createDriver() {
        return Neo4jTypes.driver(
            NeoCredentials.getUrl(),
            Neo4jTypes.auth.basic(NeoCredentials.getUsername(), NeoCredentials.getPassword()),//("neo4j", "password"),
            { disableLosslessIntegers: true }
        );
    }

    public static getDriver(): Neo4jTypes.Driver {
        if (!this.driver) {
            this.driver = this.createDriver();
            this.getStats();
        }
        return this.driver;
    }

    public static async hintGene(geneSymbol: string, setHintRows) {
        var hint_rows = [];

        var query = `
        MATCH (n:Gene)
        WHERE toLower(n.symbol) starts with toLower("${geneSymbol}")
        RETURN n.symbol as name
        `;

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        try {
            await txc.run(query).then(
                (result) => {
                    result.records.forEach((value) => {
                        hint_rows.push(value.toObject())
                    })
                });
        }
        finally {
            rxSession.close();
        }

        setHintRows([...hint_rows]);
    }

    public static async hintDisease(diseaseName: string, setHintRows) {
        var hint_rows = [];

        var query = `
        MATCH (n:Disease)
        WHERE toLower(n.name) starts with toLower("${diseaseName}")
        RETURN n.name as name
        `;

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        try {
            await txc.run(query).then(
                (result) => {
                    result.records.forEach((value) => {
                        console.log(value.toObject());
                        hint_rows.push(value.toObject())
                    })
                });
        }
        finally {
            rxSession.close();
        }

        setHintRows([...hint_rows]);
    }

    public static async findByDiseasesAndGenes(diseaseName: string, geneSymbol: string, setRowsDisgenet, setRowsOpenTarget) {

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        var dis_score = [];
        var ot_score = [];

        var queryDisgenet: string = `
        MATCH (dParent: Disease {name: "${diseaseName}"})
        MATCH p=(dParent)<-[:IS_A*0..]-(dChild)

        MATCH (dChild)-[r:HAS_ASSOCIATED_GENE]->(g: Gene {symbol: "${geneSymbol}"})
        WHERE r.source = "Disgenet"
        RETURN DISTINCT
                dChild.id as disease_id,
                dChild.name as disease_name,
                g.id as gene_id,
                g.name as gene_name,
                size([pmid in r.source_C where pmid <> '']) as pmids_C,
                size([pmid in r.source_M where pmid <> '']) as pmids_M,
                size([pmid in r.source_I where pmid <> '']) as pmids_I,
                size([pmid in r.source_L where pmid <> '']) as pmids_L,
                r.mapped_from as disgenet_id,
                length(p) as depth
        ORDER BY depth
        `;

        var queryOpenTarget: string = `
        MATCH (dParent: Disease {name: "${diseaseName}"})
        MATCH p=(dParent)<-[:IS_A*0..]-(dChild)

        MATCH (dChild)-[r:HAS_ASSOCIATED_GENE]->(g: Gene {symbol: "${geneSymbol}"})
        WHERE r.source = "OpenTarget"
        RETURN DISTINCT
                dChild.id as disease_id,
                dChild.name as disease_name,
                g.id as gene_id,
                g.name as gene_name,
                r.affected_pathway as affected_pathway,
                r.animal_model as animal_model,
                r.genetic_association as genetic_association,
                r.known_drug as known_drug,
                r.literature as literature,
                r.rna_expression as rna_expression,
                r.somatic_mutation as somatic_mutation,
                r.score as score,
                length(p) as depth
        ORDER BY depth
        `;


        try {
            await txc.run(queryDisgenet).then(
                (result) => {
                    result.records.forEach((value) => {
                        var val_obj = value.toObject();
                        val_obj['score_C'] = val_obj['pmids_C'] === 0
                            ? 0 : val_obj['pmids_C'] === 1
                                ? 0.1 : val_obj['pmids_C'] === 2
                                    ? 0.5 : 0.6;
                        val_obj['score_M'] = val_obj['pmids_M'] === 0 ? 0 : 0.2;
                        val_obj['score_I'] = val_obj['pmids_I'] === 0 ? 0 : 0.1;
                        val_obj['score_L'] = val_obj['pmids_L'] > 9 ? 0.1 : 0.01 * val_obj['pmids_L'];
                        val_obj['score'] = val_obj['score_C'] + val_obj['score_M'] + val_obj['score_I'] + val_obj['score_L']
                        dis_score.push(val_obj)
                    })
                });
            await txc.run(queryOpenTarget).then(
                (result) => {
                    result.records.forEach((value) => {
                        ot_score.push(value.toObject())
                    })
                });
        }
        finally {
            rxSession.close();
        }

        setRowsOpenTarget([...ot_score]);
        setRowsDisgenet([...dis_score]);
    }

    public static async getStats() {

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();


        try {
            await txc.run("CALL db.stats.retrieve('GRAPH COUNTS') YIELD data RETURN data").then(
                (result) => {
                    result.records[0].get('data')['relationships'].forEach((record, index) => {
                        if (record['startLabel']) {
                            if (Neo4JDriver.startToRel[record['startLabel']]) {
                                Neo4JDriver.startToRel[record['startLabel']].push(record['relationshipType']);
                            }
                            else {
                                Neo4JDriver.startToRel[record['startLabel']] = [record['relationshipType']];
                            }
                        }
                        else if (record['endLabel']) {
                            if (Neo4JDriver.relToEnd[record['relationshipType']]) {
                                Neo4JDriver.relToEnd[record['relationshipType']].push(record['endLabel']);
                            }
                            else {
                                Neo4JDriver.relToEnd[record['relationshipType']] = [record['endLabel']];
                            }
                        }
                    });
                    result.records[0].get('data')['nodes'].forEach((record, index) => {
                        if (record['label']) {
                            Neo4JDriver.labels.push(record['label']);
                        }
                    });
                });
        }
        finally {
            rxSession.close();
        }

    }

    static async getLabels(setLabels: any) {

        let labels: { value: number, label: string }[] = [];

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        try {
            await txc.run("CALL db.labels").then(
                (result) => {
                    result.records.forEach((record, index) => {
                        labels.push({
                            value: index, label: record.get('label')
                        });
                    });
                });
        }
        finally {
            rxSession.close();
            setLabels([...labels]);
        }
    }

    static async getPropertiesByLabel(label: string, setProperties: any) {
        
        let properties: { value: number, label: string }[] = [];

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        try {
            await txc.run(`MATCH (n:${label}) WITH n LIMIT 1 UNWIND keys(n) as key RETURN DISTINCT key`).then(
                (result) => {
                    result.records.forEach((record, index) => {
                        properties.push({
                            value: index, label: record.get('key')
                        });
                    });
                });
        }
        finally {
            rxSession.close();
            setProperties([...properties]);
        }
    }

    static async getIndividualsByLabel(label: string, setIndividuals: any) {

        let individuals: { value: number, label: string, labels: string[] }[] = [];

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });
        const txc = rxSession.beginTransaction();

        try {
            await txc.run(`MATCH (n:${label}) WHERE EXISTS((n)-[]-()) RETURN ID(n) as id, coalesce(n.name, n.id) as label, labels(n) as labels LIMIT 100`).then(
                (result) => {
                    result.records.forEach((record, index) => {
                        individuals.push({
                            value: record.get('id'), label: record.get('label'), labels: record.get('labels')
                        });
                    });
                });
        }
        finally {
            rxSession.close();
            setIndividuals([...individuals]);
        }
    }

    static async searchIndividualsByLabel(label: string, searchQuery: string, property: string) {

        let individuals: { value: number, label: string, labels: string[] }[] = [];

        var rxSession = Neo4JDriver.getDriver().session({
            database: NeoCredentials.getDatabase(),
            defaultAccessMode: Neo4jTypes.session.READ
        });

        const txc = rxSession.beginTransaction();

        try {
            await txc.run(`MATCH (n:${label}) WHERE n.${property} CONTAINS "${searchQuery}" RETURN ID(n) as id, coalesce(n.${property}, n.id) as label, labels(n) as labels LIMIT 100`).then(
                (result) => {
                    result.records.forEach((record, index) => {
                        individuals.push({
                            value: record.get('id'), label: record.get('label'), labels: record.get('labels')
                        });
                    });
                });
        }
        finally {
            rxSession.close();
        }

        return individuals;
    }
}

export { Neo4JDriver, NeoCredentials };
